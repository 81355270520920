import jwt_decode from 'jwt-decode';

export const getCurrentUser = () => {
  const token = getUserToken();
  let currentUser = null;
  if (token) {
    currentUser = jwt_decode(token);
  }

  return currentUser;
};

export const getUserToken = () => {
  return localStorage.getItem('token');
};
