import { useAuth } from 'core/hooks/useAuth';
import React, { createContext } from 'react';

export const Context = createContext();

const Spinner = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div
        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        role="status"
      ></div>
    </div>
  );
};
export const Provider = ({ children }) => {
  const {
    isAuthenticated,
    currentUser,
    currentCompany,
    calendarConfigured,
    facebookConfigured,
    onBoarded,
    isLoading,
    backUrl,
  } = useAuth();
  if (isLoading) return <Spinner />;

  return (
    <Context.Provider
      value={{
        isAuthenticated,
        isLoading,
        backUrl,
        currentUser,
        currentCompany,
        facebookConfigured,
        isOnboarded: onBoarded,
        calendarConfigured: calendarConfigured,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useUser() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}
