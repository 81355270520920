import Context from 'context';
import 'core/integrations';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './app';
import Spinner from './components/generic/kit/Spinner/index';

import reportWebVitals from './reportWebVitals';
const queryClient = new QueryClient();
// sentry.init();
const domNode = document.getElementById('root');
const root = createRoot(domNode);
mapboxgl.workerClass = MapboxWorker.default;

root.render(
  <Suspense fallback={<Spinner />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Context>
          <App />
        </Context>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
    <ToastContainer
      enableMultiContainer
      containerId={'A'}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <ToastContainer
      position="bottom-center"
      autoClose={false}
      newestOnTop={false}
      enableMultiContainer
      containerId={'B'}
      style={{ width: '600px' }}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
