import request from 'core/utils/request';

export const signIn = data => request('/login', 'post', { body: { ...data } });

export const register = async data => {
  return await request('/v0/register', 'post', { body: { ...data } });
};

export const checkAuth = () => request('/check-auth', 'get');

// export const sendMagicLink = data =>
//   request('/magic-link', 'post', { body: data });

export const forgotPassword = email =>
  request('/password/forgot', 'post', { body: { email } });

export const resetPassword = ({ token, password }) =>
  request('/password/reset', 'post', { body: { token, password } });

export const updateLanguage = lng =>
  request('/users/language', 'put', { body: { language: lng } });
