import axios from 'axios';
import { getUserToken } from '../../services/user';
const API_URL = process.env.REACT_APP_API_URL;
export default async function request(
  path,
  method,
  { body, headers, isFormData = false, baseUrl, params, ...axiosConfig } = {}
) {
  const token = getUserToken();
  const response = await axios({
    method: method,
    baseURL: baseUrl ? baseUrl : API_URL,
    url: path,
    params,
    data: isFormData ? body : JSON.stringify(body),
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
      ...headers,
    },
    ...axiosConfig,
  });

  return response;
}
