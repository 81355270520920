import moment from 'moment';
// import { GB, FR } from 'country-flag-icons/string/3x2';
import Flags from 'country-flag-icons/react/3x2';

export const formatEUR = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatDate = (date, format) => {
  return moment.utc(date).local(true).format(format);
};

export const getExpiryDuration = (date, measurement = 'days') =>
  moment(date).diff(moment(), measurement);

export const mapLanguageToCountry = {
  en: <Flags.GB className="w-5 h-5" />,
  fr: <Flags.FR className="w-5 h-5" />,
};

export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const isDevelopment = process.env.NODE_ENV === 'development';

export const toFormData = values => {
  let formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }

  return formData;
};

export function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}

export const priceFormatter = v =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  }).format(Number(v));

export const numericValue = v => parseFloat(String(v).replace(/[^0-9]+/g, ''));
