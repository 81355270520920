import { useUser } from 'context/user';
import { lazy } from 'react';

const AuthenticatedApp = lazy(() =>
  import(/* webpackPrefetch: true */ './routes/authenticated-routes')
);
const UnauthenticatedApp = lazy(() =>
  import('./routes/unauthenticated-routes')
);

function App() {
  const { isAuthenticated, backUrl } = useUser();

  return isAuthenticated ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp backUrl={backUrl} />
  );
}

export default App;
