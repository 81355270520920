import { isDevelopment } from 'core/utils/lib';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import Editor from 'locize-editor';
import LastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ChainedBackend from 'i18next-chained-backend';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'en',
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(LastUsed)
  .use(Editor)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'sessionStorage'],
      caches: ['localStorage'],
    },
    backend: {
      backends: [LocalStorageBackend, Backend],
      backendOptions: [
        { expirationTime: 7, caches: ['localStorage'] }, // 7 days
        locizeOptions,
      ],
    },
    locizeLastUsed: locizeOptions,
    saveMissing: isDevelopment ? true : false,
    debug: isDevelopment ? true : false,
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged editorSaved',
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: ['en', 'fr'],
    editor: {
      ...locizeOptions,
      mode: 'iframe', // 'window',

      autoOpen: isDevelopment ? true : false,
      onEditorSaved: async (lng, ns) => {
        await i18n.reloadResources(lng, ns);
        i18n.emit('editorSaved');
      },
    },
  });

export default i18n;
