import { checkAuth } from 'core/api/user';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { getUserToken } from 'services/user';

export const useAuth = () => {
  const token = getUserToken();
  const { isLoading, data: response } = useQuery(
    ['checkAuth', token],
    checkAuth,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { pathname } = useLocation();
  const shouldRedirect = pathname !== '/' && !!pathname;

  return {
    isLoading,
    isAuthenticated: response?.data?.has_valid_credentials,
    backUrl: shouldRedirect && `/?redirect=${pathname}`,
    currentUser: response?.data?.user,
    currentCompany: response?.data?.company,
    calendarConfigured: response?.data?.user?.calendarConfigured,
    facebookConfigured: response?.data?.user?.facebookConfigured,
    hasCompany: !!response?.data?.company?.name,
    onBoarded: response?.data?.user?.onboarded,
  };
};
